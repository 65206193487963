import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_modern.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Building modern IT help desk using conversation ai self-service"
        description="Learn how customers are modernizing Workplace IT Support using fast and self-service based conversational ai chatbots to resolve issues. AI Chatbots provide fast, interactive, and on-demand service, 24/7. "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            The modern employee and modern IT help desk
                            self-service
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Why is it essential to meet the demands of Gen Z
                          employees who are the latest entrants in the
                          workplace?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          By 2020, an estimated 50 percent of the world’s
                          workforce will be millennials, which means that by
                          now, most organizations are already seeing an influx
                          of Gen Z workers. The new generation of office-goers
                          co-exist with several generations of co-workers, but
                          the difference between older and new generations are
                          in their priorities, complexities, and challenges.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          As stated by Rodney Hobbs, who guides clients through
                          <a href="https://workativ.com/conversational-ai-platform/blog/itsm-digital-transformation">
                            {" "}
                            digital transformation{" "}
                          </a>
                          journeys, “employees today expect a choice of location
                          and device, flexibility in how and when they work,
                          with the increasing contingent- or project-based
                          arrangements, and they are demanding greater
                          flexibility and agility.”
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The area of significant investment for an organization
                          is its employees, and one of the emerging
                          contributions to keeping employees happy and engaged
                          is robust{" "}
                          <a href="https://workativ.com/conversational-ai-platform/for-it-helpdesk-proper-process-for-remote-working-is-the-need-of-the-hour-in-the-time-of-covid-19">
                            employee experience.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Outside of the workplace, as a consumer, employees
                          have rich technological experiences, and you may
                          wonder how this connects to a work environment.
                          Employees demand the same sort of technological
                          advancements and experiences at their workplace —
                          fast, interactive, and{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-support-24-7">
                            on-demand service, 24/7.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          To increase employee satisfaction, improving the
                          end-user experience in terms of support and service
                          should be considered
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 class="font-section-sub-header-small-bold">
                          IT helpdesk Self-service for a better end-user
                          experience
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/blog/employee-onboarding-knowledge-ai">
                            Self-service refers
                          </a>{" "}
                          to any activity where end-users solve or at least try
                          to solve a part of their issue on their own, rather
                          than relying on an IT support agent. Industry experts
                          believe that not only does it reduce operational costs
                          and helps businesses channel their resources in other
                          complex tasks, but it also reduces the time required
                          to solve the concerns of an end-user.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          It goes without saying that the higher the number of
                          tickets, the greater will be the cost levied. This
                          reason is precisely where helpdesk self-service comes
                          to the rescue!
                        </p>
                        <ul
                          class="font-section-normal-text float-left width-100"
                          style=" -webkit-padding-start:15px;"
                        >
                          <li style=" -webkit-padding-start:5px;">
                            {" "}
                            self-service is the fastest and most cost-effective
                            way to IT support
                          </li>
                          <li style=" -webkit-padding-start:5px;">
                            removes barriers to information
                          </li>
                        </ul>

                        <h6 className="font-section-sub-header-small-bold">
                          What can you do to create an effective self-service
                          for employees:
                        </h6>
                        <ul
                          class="font-section-normal-text width-100 float-left"
                          style=" -webkit-padding-start:15px;"
                        >
                          <li style=" -webkit-padding-start:5px;">
                            Examine your support ticket issues to identify the
                            most common reasons for users to contact support.
                          </li>
                          <li style=" -webkit-padding-start:5px;">
                            Create well-written resources and reasonably display
                            information.
                          </li>
                          <li style=" -webkit-padding-start:5px;">
                            {" "}
                            Invest in good web self-service software that
                            enables ticket deflection
                          </li>
                        </ul>
                      </div>
                      <div className="mt-5">
                        <h6 className="font-section-sub-header-small-bold">
                          Artificial Intelligence in the IT Self Service
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            {" "}
                            AI in IT helpdesk self-service{" "}
                          </a>
                          makes it possible for businesses to switch their
                          emphasis from reactive end-user support and service,
                          i.e., taking requests and answering questions as they
                          come to manage the end-user experience proactively.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Undeniably, using{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                            AI for the IT help desk
                          </a>{" "}
                          is one of the best strategies for streamlining
                          operations and integrating{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/for-it-helpdesk-proper-process-for-remote-working-is-the-need-of-the-hour-in-the-time-of-covid-19">
                            self-service in the IT help desk.
                          </a>
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Quick response time
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Employees experience long wait times with IT support,
                          but AI can take on common, simple, repetitive
                          questions or requests and is available 24/7
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Reduced searching for answers
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Instead of browsing through a myriad of possible
                          solutions to a question, employees would be able to
                          get answers through AI. The intelligent technology can
                          recognize the intent and context of responses to
                          provide the right answers, almost resembling a human
                          agent.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Automated resolution of IT issues
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          According to McKinsey, with the addition of
                          intelligent systems to automation solutions, workflows
                          will continue to evolve, enabling humans and machines
                          to work together. One of the hurdles in the workplace
                          is having to approach the helpdesk to fix simple
                          issues. Employees like to fix the problems on their
                          own, and the advent of AI in the helpdesk, combined
                          with automation, enables them to solve repetitive
                          issues like password reset by themselves.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            Learn more about automated resolution using
                            Workativ.
                          </a>
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Improves FCR
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          A reliable support system is one that users are happy
                          to use and are satisfied with. AI-enabled systems make
                          self-service more engaging and more reliable to
                          resolve simple issues. Fast answers and fast
                          resolution would push users to use self-service as the
                          first point of contact instead of approaching the
                          helpdesk directly. AI systems can be an effective
                          means to{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-customer-support">
                            improve FCR for support.
                          </a>
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          AI technology can also be used for sentiment analysis
                          and{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/it-support-analytics-generative-ai">
                            predictive insights
                          </a>{" "}
                          to improve end-user experience.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Your employees are your biggest asset. Small details
                          like on-time support improve the experience for your
                          employees and enable them to delight your customers.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Give your employees a good IT support experience and
                          see their productivity growth. Contact us for a demo
                          today{" "}
                          <a href="Give your employees a good IT support experience and see their productivity growth. Contact us for a demo today click here">
                            click here
                          </a>
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                                {" "}
                                10 Ways to Use AI Chatbots for Internal IT and
                                HR Support – Workativ
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
